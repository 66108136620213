<template>
    <b-form-group class="mb-0">
      <!--<b-form-select
        :options="options"
        v-model="selected"
        @change="updateRoutes"
      >
        <template #first>
          <b-form-select-option :value="null">Selecionar Sistema</b-form-select-option>
        </template>
      </b-form-select>-->
    <v-select autocomplete filterable
      :options="options.map(o => ({ label: o.text, code: o.value }))"
      placeholder="Selecionar sistema"
      v-model="selected"
      @input="updateRoutes($event.code)"
      style="width: 200px"
    >
    <div slot="no-options">Nenhuma opção encontrada</div>
  </v-select>
  </b-form-group>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    selectedSystem: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      selected: this.selectedSystem
    }
  },
  watch: {
    selectedSystem: {
      handler (newValue) {
        const obj = this.options?.find(o => o.value === newValue)
        this.selected = obj ? ({ label: obj.text, code: obj.value }) : null
      },
      immediate: true
    }
  },
  methods: {
    updateRoutes (selected) {
      // if (this.selected !== null) {
      this.$emit('system-selected', selected)
      // }
    },
    refreshModel () {
      if (typeof this.selectedSystem !== 'string') return
      const obj = this.options?.find(o => o.value === this.selectedSystem)
      this.selected = obj ? ({ label: obj.text, code: obj.value }) : null
    }
  }
}
</script>
<!--
<style lang="stylus" scoped>
.select
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    top: 6vh;
    width: min-content
    align-content: center;
</style>
 -->
