<template>
   <div>
    <Content :route="'/'" :action="action" :hasBack="action" title="Módulos" @event="goTo">
      <template #selects>
        <b-row style="flex-direction: row; justify-content: space-between; margin-top: 1%; place-content: center;">
        <SelectSystem :options="options" :selectedSystem="selectedId" @system-selected="onSystemSelected"/>
    </b-row>
      </template>
    <Interface
    noBtn
    :sortOptions="[{value: 'key', text: 'Nome'}, {value: 'description', text: 'Descrição'}]"
    :noTitle="true"
    @sort="sort"
    @filter="filterSwitch"
  >
  <Table :is-filtering="isFiltering" :filter-data="filterData" :isLoading="isLoading" :module="routeModule" :items="items" :fields="getFields" :edit="edit" :sortBy="sortBy" :sortDirection="sortDirection"></Table>
  </Interface>
</Content>
   </div>
</template>

<script>
import Interface from '@/components/Dashboard/Interface'
import Content from '../components/content/index'
import SelectSystem from '@/components/content/SelectOptions.vue'
import Table from '../components/Dashboard/Table.vue'
import { toast } from '@/utils'
export default {
  components: {
    Interface, Content, Table, SelectSystem
  },
  data: () => ({
    isFiltering: false,
    filterData: {},
    edit: null,
    items: [],
    selected: '',
    selectedId: null,
    sortBy: undefined,
    sortDirection: true,
    fields: [
      { key: 'key', label: 'Nome' },
      { key: 'description', label: 'Descrição' },
      { key: 'EditarModulo', label: 'Editar' },
      { key: 'Excluir', label: 'Excluir' }
    ],
    fieldsOptions: [
      { key: 'key', label: 'Nome' },
      { key: 'type', label: 'Tipo' },
      { key: 'description', label: 'Descrição' }
    ],
    headerOptions: null
  }),
  computed: {
    getFields () {
      const baseFields = this.fields || this.$store?.state[this.routeModule].fields

      // add extra fields to table
      if (this.$store.state.hasExtraField) {
        baseFields.push(
          ...(
            this.$store.state.extrafield.groups[this.$tore.state[this.routeModule]] || []
          ).map((e) => ({
          // format to table field
            key: e.key,
            label: e.description
          }))
        )
      }

      return [{ key: 'actions', label: '' }, ...baseFields] || [...baseFields]
    },
    action () {
      return { options: { label: 'Novo', icon: 'add', hasBack: 'arrow' } }
    },
    options () {
      if (this.$store.state.sistemas.items && this.$store.state.sistemas.items.groups) {
        const item = Object.entries(this.$store.state.sistemas.items.groups).map(a => a[1])
        return item.map(a => ({ value: a.id, text: a.key }))
      }
      return []
    },
    isFiltered () {
      return Object.entries(this.$store.state.modulos.items.groups).filter(a => a[1].key.includes(this.selected))
    },
    filteredItems () {
      if (this.selected !== '') {
        return Object.entries(this.$store.state.modulos.items.groups)
          .filter((a) => a[1].key.includes(this.selected))
          .map((a) => a[1])
      } else if (this.$route.query.group !== undefined) {
        return Object.entries(this.$store.state.modulos.items.groups)
          .filter((a) => this.$route.query.group.includes(a[1]?.key))
          .map((a) => a[1])
      } else if (this.$route.query.group === undefined || this.selected === '') {
        return Object.entries(this.$store.state.modulos.items.groups).map((a) => a[1])
      } else {
        return this.$store.state.modulos.items.groups
      }
    },
    isLoading () {
      return this.$store.state.modulos.loading
    },
    routeModule () {
      return this.$store.state.modulos
    }
  },
  beforeCreate () {
    this.$store.dispatch('modulos/init', { self: this })
    this.$store.dispatch('sistemas/init', { self: this })
  },
  created () {
    if (!this.$route.query.group) {
      toast(this, 'info', 'Módulos', 'Selecione um sistema para começar')
    } else {
      this.selected = this.$route.query.group
    }
  },
  mounted () {
    if (
      this.$store.state.sistemas &&
    this.$store.state.sistemas.items &&
    this.$store.state.sistemas.items.groups
    ) {
      this.items = this.filteredItems
      const item = Object.entries(this.$store.state.sistemas.items.groups).map((a) => a[1])
      this.headerOptions = item.map((a) => ({ value: a.key }))
      // this.isLoading = false
    }
  },
  updated () {
    if (
      this.$store.state.sistemas &&
    this.$store.state.sistemas.items &&
    this.$store.state.sistemas.items.groups
    ) {
      this.items = this.filteredItems
      const item = Object.entries(this.$store.state.sistemas.items.groups).map((a) => a[1])
      this.headerOptions = item.map((a) => ({ value: a.key }))
      // this.isLoading = false
    }
  },
  watch: {
    filterData: {
      handler (value) {
        Object.keys(value).forEach((key) => {
          if (value[key] === '') {
            // console.log("empty: " + value);
            delete value[key]
          }
        })
      },
      deep: true
    }
  },
  methods: {
    filterSwitch (value) {
      this.isFiltering = value
      if (!value) this.filterData = {}
    },
    sort (key, direction) {
      this.sortBy = key
      this.sortDirection = direction
    },
    onSystemSelected (systemId) {
      this.selectedId = systemId
      this.selected = this.options.find(a => a.value === systemId)?.text
      this.getItems()
    },
    getItems () {
      this.items = this.filteredItems
      return this.items.map(a => a)
    },
    goTo () {
      this.$router.push({ name: 'CadastrarModulos' })
    }
  }
}
</script>
  <style scoped lang="stylus">
  @import '../style/colors';
  @import '../style/fonts.styl';
  @import '../style/mixins.styl';

  .wrapper
    padding-top: 0 !important

#select-title{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: min-content
    align-content: center;
}

#sort-by-select{
  border: solid 1px #9A9A9A
}
  .table {
    color: greyLight !important;
    border-collapse: separate;
    border-spacing: 0 1em;
  }

  .table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default {
    background-color: light !important;
    color: black;
  }

  .table td, .table th, .table thead th {
    border: none;
    white-space: nowrap;
  }

  .table thead th {
    padding: 0 0.75rem;
    font-size: 20px;
  }

  .table tr {
    // background-color content-base-theme()
    background-color: primary;
    transition: 0.4s;

    &:focus {
      outline: none;
    }
  }

  radius = 10px;

  .table tr td:first-child {
    border-top-left-radius: radius;
    border-bottom-left-radius: radius;
    width: 8%;
  }

  .table tr td:last-child {
    border-top-right-radius: radius;
    border-bottom-right-radius: radius;
  }

  .table th div {
    nowrap();
  }

  .table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td {
    background-color: secondary !important;
  }

  .table.b-table tr {
    .btn-actions {
      min-width: 64px;
      display: inline;
    }

    i {
      opacity: 0;
      transition: 0.3s;
      background-color: green;
    }

    .loading-btn {
      opacity: 0;
      transition: 0.3s;
      position relative

      span {
        position absolute
        right 10px
        bottom -1px
      }
    }

    &:hover {
      i {
        opacity: 1;
      }

      .loading-btn {
        opacity: 1;
      }
    }
  }

  .ec {
    align-self: flex-end;
  }

  .btn {
    justify-content: space-between;
    margin-left: 10px;
    float: left;
  }

  .btn-service {
    display: inline;
    margin-left: 0px;
  }

  .checkbox, .radio {
    display: block;
    width: 25px;
    height: 25px;
    background-color: light;
    // padding 20px
    border: 1px solid black;
    cursor: pointer;
    margin-top: 4px;

    .fill-selected {
      min-height: 100%;
      // border-radius: 4px
      transition: 0.2s;
      transform: scale(0.2);
    }

    .active {
      background-color: green;
      width: auto;
      height: auto;
      transform: scale(1);
    }
  }

  .radio {
    &, .fill-selected {
      border-radius: 50%;
    }
  }

  #remessa___BV_modal_header_, #remessa___BV_modal_footer_ {
    display: none;
  }

  #remessa___BV_modal_content_ {
    background-color: light;
    border-radius: 8px;
  }

  .title-btn-2 {
    background-color: blue;
    color: white;
    border-radius: 5px;
    padding: 6px 30px;
    cursor: pointer;
    margin-top: -4.5rem;
    position: absolute;
    top: -166px;
    right: 179px;
    font-family: Calibri;
    height: 38px;
    display: flex;
    align-items: center;

    .spinner-border {
      width: 1.25rem !important;
      height: 1.25rem !important;
    }

    &:hover {
      background-color: alpha(primary, 0.8);
      transition: 0.2s;
    }

    span, .icon-btn {
      vertical-align: middle;
      no-select();
    }
  }

  .btn-icon
      border-radius: 50%
      background-color primary
      color white
      padding 5px
      cursor pointer
      no-select()
      transition .3s
      margin 0 8px

      &:hover
          background-color primary

  .btn-icons
      display flex
      justify-content center

      .title
          padding 0px 10px
          font-size 14px
          color light
          font-weight bold
          border-radius 1rem
          background primary

      span
          align-self center

      span:last-child
          border 1px dashed primary
          height 1px

      span:last-child
          flex-grow 1
  .btn
    justify-content space-between
    margin-left 10px
    float left

  .btn-service
    float left
    margin-left 1px
    border-radius 50%
    margin-top 20px

  .service
    width 50%
    float right

  .btn-icon-red
      background-color red !important

  .table
      color greyLight !important
      border-collapse separate
      border-spacing 0 1em

  .table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default
      background-color light !important
      color black

  .table td, .table th, .table thead th
      border none
      white-space nowrap

  .table thead th
      padding 0 .75rem
      font-size 20px

  .table tr
      //background-color content-base-theme()
      background-color primary
      transition .4s

  radius = 10px

  .table tr td:first-child
      border-top-left-radius radius
      border-bottom-left-radius radius
      width 8%

  .table tr td:last-child
      border-top-right-radius radius
      border-bottom-right-radius radius

  .table th div
      nowrap()

  .table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td
      background-color secondary !important

  .table.b-table tr
      .btn-actions
          min-width 64px
      i
          opacity 0
          transition .3s
          background-color green

      &:hover
          i
              opacity 1

  </style>
